
import store from '@/store'
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  components: {},
  setup() {
    // 2.开始登录验证
    onMounted(() => {
      store.dispatch('login/accountLoginAction')
    })

    return {}
  },
})
